/* .Title {
    max-width: 90%;
    margin: 0 auto;
} */

/* .Content {
    margin-top: 2%;
} */

.LoadingContent {
    display: flex;
    justify-content: center;
}

.Filters {
    display: flex;
}

.CategoryFilter, .SizeFilter, .ColorFilter {
    flex: 1 1 0;
    text-align: left;
}

.CategoryFilter .Header, .SizeFilter .Header, .ColorFilter .Header {
    font-weight: bold;
}

.Filter {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10%;
}

.Filter label {
    margin-top: 8%;;
}

.Filter input {
    margin-right: 10%;
}

.ProductsListing {
    list-style-type: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
    margin: 0 auto;
}

@media screen and (min-width: 1279px) {
    .Content {
        display: grid;
        grid-template-columns: 15% 1fr;
    }

    .Filters {
        display: block;
        position: relative;
        width: 80%;
    }

    .ShowFiltersResults {
        display: none;
    }

    .FiltersButton {
        display: none;
    }

    .CategoryFilter, .SizeFilter, .ColorFilter {
        border-bottom: 0.5px solid #000;
        padding: 1.5rem;
    }

    .ProductsListing {
        grid-column: 2/3;
    }
}

@media screen and (max-width: 1279px) {

    .ShowFiltersResults {
        padding: 3%;
        margin: 0 5%;
        font-size: var(--fs-smaller);
        background-color: rgb(229, 213, 190);
        border: none;
        border-radius: 0.3rem;
        cursor: pointer;
        transition: 0.3s;
        color: black;
    }

    .FiltersButton {
        margin: 0 5%;
        padding: 0%;
        font-size: 1em;
        background-color: #faf9f8;
        border: none;
        display: flex;
    }

    .FiltersButton .FiltersButtonIcon {
        font-size: 1.5em;
        color: #000;
    }

    .FiltersButton .FiltersButtonText {
        margin-top: 5%;
        color: #000;
    }

    .ShowFiltersResults {
        float: right;
    }

    .ShowFiltersResults:hover, .FiltersButton:hover {
        background-color: rgb(176, 151, 106);
    }

    .Filters {
        transform: translateX(-100%);
        background: white;
        z-index: 200;
        position: fixed;
        transition: transform 0.3s ease-out;
        display: block;
        height: 100%;
        width: 100%;
    }

    .Filters.Open {
        transform: translateX(0);
        overflow: auto;
        height: calc(100% - 64px);
        
    }

    .CategoryFilter, .SizeFilter, .ColorFilter {
        margin: 3%;
    }

    .Filter {
        margin-top: 0;
    }

    .Filter input {
        margin-right: 3%;
    }

    .Filter label {
        margin-top: 4%;
    }

}

.ProductsMenu {
    display: flex;
    overflow-x: auto;
}

.ProductsMenu .ProductsMenuItem {
    margin-right: 3%;
    padding: 8px 25px 7px;
    background-color: #faf9f8;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
    color: #878787;
}

.ProductsMenu .ProductsMenuItemActive {
    border-radius: 1rem !important;
    border: 1px solid #222 !important;
    color: #000;
}

.ProductsListing {
    min-height: 30rem;
}

.ProductsListing a {
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 767px) {

    .ProductsMenu {
        margin-bottom: 3%;
    }

    .ProductsMenu, .ProductsListing {
        padding: 3% 5% 0 5%;
    }

    .ProductsMenu .ProductsMenuItem {
        font-size: 1em;
    }
}

@media screen and (min-width: 768px) {

    .ProductsMenu {
        margin-top: 1.5%;
        margin-bottom: 1.5%;
    }
    
    .ProductsMenu .ProductsMenuItem {
        font-size: 1.2em;
    }

    .ProductsListing {
        padding: 0;
    }
}

.ProductsItem {
    display: inline-block;
    border: 1px solid #e3e3e3;
    vertical-align: top;
    box-sizing: border-box;
    width: calc(100% / 2 - 4px - -4px / 2 - 0.01px);
    margin-left: 0;
    padding: 0.5%;
}

.ProductsItem .ItemImage {
    overflow: hidden !important;
    position: relative;
    width: 100%;
}

.ProductsItem .ItemImage .ItemImageSrc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.ProductsItem .ItemDetails {
    min-height: 65px;
}

@media screen and (max-width: 479px) {
    .ProductsItem {
        width: calc(100% / 2 - 4px - -4px / 2 - 0.01px);
    }

    
}

@media screen and (min-width: 480px) and (max-width: 1279px) {
    .ProductsItem {
        width: calc(100% / 3 - 4px - -4px / 3 - 0.01px);
    }

}

@media screen and (min-width: 1280px) {
    .ProductsItem {
        width: calc(100% / 4 - 4px - -4px / 4 - 0.01px);
    }

}

.ProductsItem .ItemImage .ItemImageSrc {
    min-height: 20vh;
}

.ProductsItem img {
    width: 100%;
    position: relative;
    height: auto;
}

.ProductsItem h4 {
    margin: 12px 0 3px;
}

.ProductsItem .ItemPrice .OldPrice {
    position: relative;
    font-weight: normal;
}

.ProductsItem .ItemPrice .OldPrice:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    
    -webkit-transform:rotate(-25deg);
    -moz-transform:rotate(-25deg);
    -ms-transform:rotate(-25deg);
    -o-transform:rotate(-25deg);
    transform:rotate(-25deg);
}

.ProductsItem .ItemPrice .NewPrice {
    color: #b50909;
}