.ProductPersonalization {
    margin-top: 3%;
    margin-bottom: 4%;
}

.PersonalizationDetails {
    margin-top: 4%;
}

.PersonalizationDetails .Input {
    text-align: left;
    margin: 0px auto 2rem;
}

.PersonalizationDetails .Input .Label {
    display: block;
    margin-bottom: 0.5rem;
}

.PersonalizationDetails .Input .Value {
    width: 100%;
    height: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 1%;
    color: black;
    background: white;
}

.PersonalizationDetails .Input .Value:placeholder-shown {
    font-style: italic;
}