@media screen and (min-width: 1279px) {
    .carousel-root {
        width: 80%;
        margin: 0 auto;
    }
}

.carousel.carousel-slider .control-arrow {
    display: none;
}

.carousel.carousel-slider .legend {
   background-color: rgb(229, 213, 190) !important;
   border-radius: 0;
   width: 32%;
   left: 55%;
   color: #000 !important;
   text-decoration: none;
   font-weight: 550;
   font-style: italic;
   font-size: medium;
}

.carousel.carousel-slider .legend:hover {
    background-color: rgb(176, 151, 106) !important;
}

.carousel .thumbs-wrapper.axis-vertical {
    display: none;
}

@media screen and (max-width: 1279px) {
    .carousel.carousel-slider .legend {
        font-size: small;
        padding: 5px;
        bottom: 30px;
    }
}

.control-dots .dot {
    width: 12px !important;
    height: 12px !important;
    background:rgb(176, 151, 106) !important;
}