.toolbar {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgb(255, 255, 255);
    height: 64px;
    z-index: 10;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo {
    width: 10%;
}

.toolbar__logo a {
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
}

.toolbar__logo img {
    width: 150px;
    height: 60px;
    margin-top: 5%;
    margin-left: 5%;
}

.spacer1 {
    flex: 0.56;
}

.toolbar__navigation-items {
    width: 70%;
}

.toolbar__navigation-search {
    width: 100%;
    display: flex;
    justify-content: right;
}

.toolbar__navigation-search input {
    width: calc(100% - 15rem);
    height: 2.4rem;
    border: 0.1rem solid #d0d5da;
    border-right: none;
    font-size: 1.1rem;
    padding-left: 1rem;
    text-transform: uppercase;
    line-height: 2.2rem;
    font-style: italic;
    font-weight: bold;
    background: #eff1f3;
    color: #43505c;
    border-radius: 0.2rem 0 0 0.2rem;
}

.toolbar__navigation-search .button-search {
    background: #fff;
    color: black;
    width: 5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 0.1rem solid #d0d5da;
    border-left: none;
    position: relative;
    outline-offset: -0.2rem;
    z-index: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.button-search-phone {
    background: #fff;
    width: 5rem;
    height: 2rem;
    align-items: center;
    display: flex;
    display: -webkit-flex;
    justify-content: right;
    -webkit-justify-content: flex-end;
    border: none;
    position: relative;
    outline-offset: -0.2rem;
    z-index: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.button-search-phone svg {
    height: 20px;
    position: relative;
    width: 20px;
    color: #000;
}

@media (min-width: 769px) {
    .toolbar__navigation-items .button-search-phone {
        display: none;
    }

    .toolbar__navigation-search {
        opacity: 1;
        visibility: visible;
        transform: none;
    }
}

@media (max-width: 768px) {
    .toolbar__logo {
        width: 70%;
    }

    .toolbar__navigation-items {
        width: 30%;
    }

    .toolbar__navigation-items {
        display: flex;
        display: -webkit-flex;
        justify-content: right;
        -webkit-justify-content: flex-end;
    }

    .toolbar__navigation-search {
        width: 100%;
        position: absolute;
        border-top: 0.1rem solid #d0d5da;
        border-bottom: 0.1rem solid #d0d5da;
        background-color: #fff;
        top: 100%;
        transform: none;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    .toolbar__navigation-search input {
        width: 100%;
    }

    .toolbar__navigation-search.open {
        opacity: 1;
        visibility: visible;
    }
}

.toolbar__navigation-search svg {
    height: 20px;
    position: relative;
    width: 20px;
}

.spacer2 {
    flex: 0.39;
}

@media (max-width: 975px) {
    .toolbar__cart-description {
        display: none;
    }
}

.toolbar__cart-items {
    width: 20%;
    height: 2rem;
    display: flex;
    display: -webkit-flex;
    justify-content: right;
    -webkit-justify-content: flex-end;
    /* background: linear-gradient(90deg, #fff 20%, rgb(229, 213, 190) 80%); */
}

@media (min-width: 769px) {

    .toolbar__cart-items {
        margin-right: 0;
    }
}

.toolbar__cart-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar__cart-items li {
    white-space: normal;
    display: inline-block;
}

.toolbar__cart-items a {
    color: #000;
    text-decoration: none;
}

.toolbar__cart-items svg {
    color: #000;
    height: 20px;
    position: relative;
    top: 5px;
    width: 20px;
    margin-right: 5px;
}

@media (max-width: 768px) {
    .toolbar__cart-items {
        width: 30%;
    }
}