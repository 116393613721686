.Product {
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 100rem;
}

.LoadingContent {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1280px) {
    .Product {
        margin: 0 1.5rem;
    }
}

.Summary {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.Images {
    width: 60%;
    display: flex;
}

.Gallery {
    width: 7rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Gallery img {
    cursor: pointer;
    padding: 0.5rem;
}

.Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 7rem);
}

.Image img {
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.Border {
    width: 0.2px;
    background-color: rgba(0,0,0,.2);
}

.Product .Summary .Details {
    margin-top: 2%;
    width: 25%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.Product .Summary .Details .Title {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
    .Summary {
        width: 100%;
        flex-flow: column;
    }

    .Images {
        width: 100%;
    }

    .Product .Summary .Details {
        margin-top: 0;
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .Gallery {
        margin-right: 1rem;
        width: 5rem;
    }

    .Image {
        width: calc(100% - 5rem);
    }

    .Image img {
        max-width: 100%;
        max-height: 100%;
    }

    .AdditionalDetails {
        padding: 1rem 0;
    }
    
    .MainRating {
        justify-content: center;
    }
}

.Stocks {
    margin-top: 1rem;
}

.AddToCart {
    background-color: #222;
    padding: 13px 25px;
    margin-bottom: 1rem;
    min-height: 48px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: white;
    border: none;
    cursor: pointer;
}

.AddToCart svg {
    height: 16px;
    position: relative;
    top: 3px;
    width: 16px;
}

.AdditionalDetails {
    padding: 2rem 0;
    text-align: left;
}

.AdditionalDetails ul {
    text-align: left;
}

.Product .Summary .Details .Price {
    margin-top: 8%;
}

.Product .Summary .Details .Price .OldPrice {
    position: relative;
}

.Product .Summary .Details .Price .OldPrice:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    
    -webkit-transform:rotate(-25deg);
    -moz-transform:rotate(-25deg);
    -ms-transform:rotate(-25deg);
    -o-transform:rotate(-25deg);
    transform:rotate(-25deg);
}

.Product .Summary .Details .Price .NewPrice {
    color: #b50909;
}

/* REVIEWS */

@media only screen and (max-width: 768px) {
    .Image a {
        max-width: 90%;
    }

    .Reviews {
        width: 100%;
    }

    .Reviews .AddReview .AddReviewButton {
        padding: 2%;
    }

    .Reviews .GeneralRating {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
    }

    .Reviews .GeneralRating .Mark {
        border-bottom: 1px solid #e3e3e3;
        padding-left: 3%;
        text-align: center;
    }

    .Reviews .GeneralRating .Mark .Average {
        font-size: 50px;
        line-height: 58px;
        margin: 10px 0 10px;
        color: #666;
    }

    .Reviews .GeneralRating .DetailedSummary {
        padding-left: 3%;
    }

    .Reviews .GeneralRating .AddReview {
        padding-left: 3%;
        display: flex;
        align-items: center;
    }

    .Reviews .Conversations .List {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .Reviews .Conversations .List .Item {
        border-bottom: 1px solid #e3e3e3;
    }

    .Reviews .Conversations .List .Item .UserDetails {
        padding-left: 3%;
    }

    .Reviews .Conversations .List .Item .ReviewDetails {
        padding-left: 3%
    }

    .Reviews .GeneralRating .DetailedSummary .ProgressBar {
        width: 60%;
    }

    .Reviews .GeneralRating .DetailedSummary p {
        width: 20%;
    }
}

@media only screen and (min-width: 769px) {

    .Image a {
        max-width: 80%;
    }

    .Reviews {
        width: 80%;
        margin: 0 auto;
    }

    .Reviews .AddReview .AddReviewButton {
        padding: 1%;
    }

    .Reviews .GeneralRating {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        display: flex;
    }

    .Reviews .GeneralRating .Mark {
        width: 27%;
        padding-left: 3%;
        border-right: 1px solid #e3e3e3;
    }

    .Reviews .GeneralRating .Mark .Average {
        font-size: 50px;
        line-height: 58px;
        margin: 10px 0 10px;
        color: #666;
    }

    .Reviews .GeneralRating .DetailedSummary {
        width: 67%;
        padding-left: 3%;
        text-align: center;
    }

    .Reviews .Conversations .List {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .Reviews .Conversations .List .Item {
        display: flex;
        border-bottom: 1px solid #e3e3e3;
    }

    .Reviews .Conversations .List .Item .UserDetails {
        width: 27%;
        padding-left: 3%;
    }

    .Reviews .Conversations .List .Item .ReviewDetails {
        width: 67%;
        padding-left: 3%
    }

    .Reviews .GeneralRating .DetailedSummary .ProgressBar {
        width: 70%;
    }

    .Reviews .GeneralRating .DetailedSummary p {
        width: 15%;
    }
}

.StarRating {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    vertical-align: top;
    color: #ccc;
    letter-spacing: 2px;
    font-size: 20px;
    height: 20px;
    line-height: 20px;
}

.MainRating {
    cursor: pointer;
    display: flex;
    color: #666;
    font-size: 20px;
    height: 20px;
    line-height: 20px;
}

.StarRating::before {
    position: relative;
    content: "\2605\2605\2605\2605\2605";
    display: inline-block;
}

.StarRatingInner {
    white-space: nowrap;
    position: absolute;
    line-height: inherit;
    height: inherit;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.StarRatingInner::before {
    position: relative;
    content: "\2605\2605\2605\2605\2605";
    color: #f9bf3b;
}

.Reviews .AddReview {
    margin-bottom: 1em;
}

.Reviews .AddReview .AddReviewButton {
    margin: 0 auto;
    font-size: var(--fs-smaller);
    background-color: rgb(229, 213, 190);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.Reviews .AddReview .AddReviewButton:hover {
    background-color: rgb(176, 151, 106);
}

.Reviews .GeneralRating .DetailedSummary .Review5Stars,
.Reviews .GeneralRating .DetailedSummary .Review4Stars,
.Reviews .GeneralRating .DetailedSummary .Review3Stars,
.Reviews .GeneralRating .DetailedSummary .Review2Stars,
.Reviews .GeneralRating .DetailedSummary .Review1Stars {
    display: flex;
    height: 2em;
    align-items: center;
}

.Reviews .GeneralRating .DetailedSummary .ProgressBar {
    margin-left: 5%;
    margin-right: 5%;
    height: 20px;
    border-radius: 10px;
}

.Reviews .GeneralRating .DetailedSummary .Review5Stars .ProgressBar {
    background-color: #70be1a;
    opacity: 0.8;
}

.Reviews .GeneralRating .DetailedSummary .Review4Stars .ProgressBar {
    background-color: #b0cb1e;
    opacity: 0.8;
}

.Reviews .GeneralRating .DetailedSummary .Review3Stars .ProgressBar {
    background-color: #fbae06;
    opacity: 0.8;
}

.Reviews .GeneralRating .DetailedSummary .Review2Stars .ProgressBar {
    background-color: #fbae06;
    opacity: 0.8;
}

.Reviews .GeneralRating .DetailedSummary .Review1Stars .ProgressBar {
    background-color: #db7b25;
    opacity: 0.8;
}

.ModalReviewProduct {
   text-align: center;
}

.ModalReviewProduct {
    color: rgba(0,0,0,.7);
    font-size: 26px;
}

.ModalReview {
    margin-bottom: 10%;
}

.ModalReviewName {
    font-size: 15px;
    margin-top: 4%;
}

.ModalReviewText {
    width: 100%;
    resize: none;
    min-height: 5.5rem;
    font-family: "Roboto",Arial,sans-serif;
    font-size: 15px;
    margin-top: 4%;
}

.ModalButtons {
    /* display: flex; */
    text-align: right;
}

.ModalButtons .ModalButtonCancel{
    margin: 0 6px 0 6px;
    padding: 2%;
    font-size: var(--fs-smaller);
    background-color: rgb(229, 213, 190);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.ModalButtons .ModalButtonCancel:hover {
    background-color: rgb(176, 151, 106);
}

.ModalButtons .ModalButtonSubmit {
    margin: 0 6px 0 6px;
    padding: 2%;
    font-size: var(--fs-smaller);
    background-color: rgb(229, 213, 190);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.ModalButtons .ModalButtonSubmit:hover {
    background-color: rgb(176, 151, 106);
}

.ModalButtons button {
    flex: 1 1;
}

.ModalReviewMark {
    white-space: nowrap;
    line-height: inherit;
    height: inherit;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    margin-top: 4%;
}

.ModalReviewMark:before {
    content: " ";
    display: table;
}

.ModalReviewMark .ModalReviewMark1Star, 
.ModalReviewMark .ModalReviewMark2Star,
.ModalReviewMark .ModalReviewMark3Star,
.ModalReviewMark .ModalReviewMark4Star,
.ModalReviewMark .ModalReviewMark5Star {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: inherit;
    outline: none;
    display: block;
    float: left;
    letter-spacing: 14.99px;
    cursor: pointer;
    color: #ccc;
    font-size: 32px;
    height: 32px;
    line-height: 32px;
}

.ModalReviewMarkDescription {
    padding: 0;
    margin: 0;
    font-size: inherit;
    outline: none;
    display: block;
    float: left;
    font-size: 25px;
    height: 32px;
    line-height: 38px;
}

.ModalReviewMark .ModalReviewMark1Star::before, 
.ModalReviewMark .ModalReviewMark2Star::before,
.ModalReviewMark .ModalReviewMark3Star::before,
.ModalReviewMark .ModalReviewMark4Star::before,
.ModalReviewMark .ModalReviewMark5Star::before {
    content: "\2605";
}

.ModalReviewMark .ModalReviewMarkStarSelected {
    color: #f9bf3b;
}