.badge-check svg {
    height: 20px;
    position: relative;
    width: 20px;
    color: #009900;
    top: 4px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    border-radius: 10px !important;
    box-shadow: inset 0 0 3px rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 768px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 70%;
        padding: 40px !important;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open {
        z-index: 1;
    }
}

@media only screen and (min-width: 769px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 35%;
        padding: 40px !important;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open {
        z-index: 1;
    }
}

