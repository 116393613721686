.Content {
    max-width: 70rem;
    margin: 0 auto;
    text-align: center;
    padding: 2% 5%;
}

.Form {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.Form .Input {
    text-align: left;
    width: 50%;
    margin: 0px auto 2rem;
}

.Form .Input .Label {
    display: block;
    margin-bottom: 0.5rem;
}

.Form .Input .Value {
    width: 100%;
    height: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 1%;
    color: black;
    background: white;
}

.Form .Input .Value:placeholder-shown {
    font-style: italic;
}

.Error.Hide {
    display: none;
}

.Error.Show {
    display: block;
}

.Error ul {
    list-style: none;
}

.Form button {
    padding: 1rem;
    font-size: var(--fs-smaller);
    background-color: rgb(229, 213, 190);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.Form button:hover {
    background-color: rgb(176, 151, 106);
}

@media only screen and (max-width: 768px) {
    .Form .Input {
        width: 100%;
    }

    .Error ul {
        text-align: left;
        padding: 0;
    }
}