.Content {
    max-width: 100rem;
    padding: 1rem 0;
    margin: 0 auto;
}

.MainMenu .Items, .ProductsMenu .Items, .OrdersMenu .Items {
    display: flex;
    list-style: none;
}

.ProductsMenu .ProductsTable, .OrdersMenu .OrdersTable, .ReviewsMenu .ReviewsTable, .VouchersMenu .VouchersTable {
    width: 100%;
}

.ProductsMenu .ProductName {
    flex: 1;
}

.ProductsMenu .ProductImage {
    width: 7%;
}

.ProductsMenu .ProductsTable tr:nth-child(even), .OrdersMenu .OrdersTable tr:nth-child(even), .ReviewsMenu .ReviewsTable tr:nth-child(even), .VouchersMenu .VouchersTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ProductsMenu .ProductsTable tr:hover, .OrdersMenu .OrdersTable tr:hover, .ReviewsMenu .ReviewsTable tr:hover, .VouchersMenu .VouchersTable tr:hover {
    background-color: #ddd;
}

.ProductsMenu .ProductsTable tr td, .OrdersMenu .OrdersTable tr td, .ReviewsMenu .ReviewsTable tr td, .VouchersMenu .VouchersTable tr td {
    border-bottom: 1px solid #ddd;
    padding: 1%;
    max-width: 7em;
}

.ProductsMenu .ProductsTable tr td, .OrdersMenu .OrdersTable tr td, .ReviewsMenu .ReviewsTable tr td, .VouchersMenu .VouchersTable tr td {
    text-align: left;
}

.ProductsMenu .ProductsTable tr th, .OrdersMenu .OrdersTable tr th, .ReviewsMenu .ReviewsTable tr th, .VouchersMenu .VouchersTable tr th {
    text-align: left;
    background-color: #04AA6D;
    padding: 1%;
}

.awb, .Status {
    margin-bottom: 2%;
}
