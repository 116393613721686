.Footer {
    position: absolute;
    bottom: 0;
    height: 15rem;
    width: 100%;
    font-size: var(--fs-smaller);
    color: var(--text-light);
    background-color: rgb(255, 255, 255);
}

@media screen and (min-width: 0) and (max-width: 479px) {
    .Footer {
        height: 32rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 1279px) {
    .Footer {
        height: 26rem;
    }
}

.Content {
    height: 12rem;
    max-width: 70rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 0.5px solid #000;
    font-weight: 300;
    z-index: -1;
}

@media only screen and (max-width: 1264px) {
    .Content {
        margin: 0 3.2rem;
    }
}

@media screen and (min-width: 0) and (max-width: 479px) {
    .Content {
        height: 30rem;
        flex-flow: column;
    }
    .Content > div:not(:last-child) {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 1279px) {
    .Content {
        height: 26rem;
        flex-flow: column;
    }
    .Content > div:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.Contact div {
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.Contact ul li {
    margin-bottom: 1%;
}

.Contact ul li a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: rgb(176, 151, 106);
}

.Contact ul li svg {
    display: block;
    height: 20px;
    position: relative;
    width: 20px;
}

.List {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.List.Social {
    display: flex;
    justify-content: space-between;
}

.List.Social a {
    color: black;
}

.List.Social a svg {
    width: 25px;
    height: 25px;
}

.Copyright {
    height: 3rem;
    text-align: center;
    font-size: var(--fs-smallest);
    background-color: rgb(255,255,255);

    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .Copyright {
        height: 2rem;
    }
}