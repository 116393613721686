.Order {
    max-width: 80rem;
    margin: 0 auto;
}

.Cart {
    text-align: center;
}

.Content {
    display: flex;
    width: 100%
}

.Products {
    width: 65%;
}

.Products ul {
    padding: 1%;
    margin: 2%;
}

.Title {
    font-size: var(--fs-smaller);
}

.Product {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 4% 0;
    border-bottom: 1px solid #e3e3e3;
}

.Image {
    height: 7em;
    width: 5rem;
    margin-right: 2rem;
}

.Details {
    width: 100%;
    height: 7rem;
}

.Details a {
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
}

.Details a:hover {
    color: rgb(176, 151, 106);
}

.Details .Name {
    margin-top: 0;
}

.Info {
    display: flex;
}

.Details span {
    flex: 1 1 0%;
}

.Size {
    text-align: left;
}

.Color {
    text-align: center;
}

.Price {
    text-align: right;
}

.Actions {
    display: flex;
    margin-top: 1.5rem;
}

.Actions svg {
    height: 20px;
    position: relative;
    width: 20px;
}

.Actions .Quantity {
    display: flex;
    flex: 1 1;
}

.Actions .Quantity span {
    margin-right: 5%;
}

.Actions .Quantity svg {
    cursor: pointer;
}

.Actions .Delete {
    flex: 1 1;
    text-align: right;
}

.Actions .Delete svg {
    cursor: pointer;
}

.Actions span {
    flex: none;
}

.Border {
    width: 0.5px;
    background-color: rgba(0,0,0,.2);
}

.Summary {
    width: 25%;
    padding: 0 1%;
}

.Total {
    padding: 1%;
    margin: 2%;
}

.Total span {
    margin-bottom: 5%;
}

.Apply {
    margin: 5% 0;
}

.Apply button {
    width: 100%;
    padding: 3%;
    font-size: var(--fs-smaller);
    background-color: rgb(229, 213, 190);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.Apply button:hover {
    background-color: rgb(176, 151, 106);
}

@media only screen and (max-width: 768px) {
    .Products {
        width: 100%;
    }

    .Content {
        flex-direction: column;
    }

    .Actions .Quantity {
        justify-content: space-between;
    }

    .Border {
        margin: 4%;
    }

    .Summary {
        width: 100%;
        border-top: 0.5px solid #000;
    }
}

.DeliveryFee {
    font-style: italic;
}

.Voucher {
    border-top: 0.5px solid #000;
    display: flex;
    justify-content: space-between;
}

.Voucher .Code {
    margin-top: 4%;
    width: 70%;
    padding: 3%;
}

.Voucher .ApplyVoucher {
    margin-top:4%;
    width: 20%;
    background: white;
    cursor: pointer;
    border: 1px solid transparent;
    border-color: rgb(176, 151, 106);
    border-radius: 0.3rem;
    text-decoration: none;
}

.OldTotal {
    position: relative;
    font-weight: normal;
    margin: 0 2%;
}

.NewTotal {
    margin-right: 1%;
}

.OldTotal::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    
    -webkit-transform:rotate(-25deg);
    -moz-transform:rotate(-25deg);
    -ms-transform:rotate(-25deg);
    -o-transform:rotate(-25deg);
    transform:rotate(-25deg);
}