.Content {
    max-width: 100rem;
    padding: 1rem 0;
    margin: 0 auto;
}

.Information {
    background: url('../../../public/media/utils/beige-25.jpeg') center center no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: space-between;
    padding: 5%;
    margin-top: 3%;
}

.DeliveryTime, .DeliveryFee, .ReturnTime {
    text-align: center;
}

.DeliveryTime svg, .DeliveryFee svg, .ReturnTime svg {
    width: 25px;
    height: 25px;
}

.Gallery {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
}

.Gallery img {
    height: auto;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 1279px) {
    .Information {    
        grid-template-columns: 50% 50%;
    }

    .Information h3 {
        font-size: small;
    }
}

@media screen and (min-width: 1279px) {
    .Content {
        width: 80%;
        margin: 0 auto;
    }
}