main {
    padding-bottom: 18rem;
}

@media screen and (min-width: 0) and (max-width: 479px) {
    main {
        padding-bottom: 35rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 1279px) {
    main {
        padding-bottom: 30rem;
    }
}

body {
    font-family: "Roboto",Arial,sans-serif;
}